<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-input v-model="tableQuery.name" style="width: 200px;margin-right:10px" placeholder="请输入名称"></el-input>
      <el-button type="danger" @click="newProcess">搜 索</el-button>
    </div>
    <el-table
        :data="tableData" class="table"
        row-key="id"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="date"
          label="日期"
          sortable
          width="180">
      </el-table-column>
      <el-table-column
          prop="name"
          label="姓名"
          sortable
          width="180">
      </el-table-column>
      <el-table-column
          prop="address"
          label="地址">
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: right;margin: 20px 10px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, prev, pager, next, jumper"
        :total="400">
    </el-pagination>
  </div>
</template>

<script>
import '/src/assets/styles.css';
import {selectDingHistorical} from '/src/api/dingdingPastRecords'
export default {
  name: "DingDingPastRecords",
  data() {
    return {
      tableQuery:{

      },
      tableData: [{
        id: 1,
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        id: 2,
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        id: 3,
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄',
        children: [{
          id: 31,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          id: 32,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }]
      }, {
        id: 4,
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
    }
  },
  created() {
    selectDingHistorical().then(value => {
      console.log(value)
    })
  }
}
</script>

<style scoped>
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 10px 10px 10px;
  height: 550px;
}
</style>